var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f1bd18bf71665a2076831aaabc6435bf1bc0dd9e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
    profilesSampleRate: 0.05,
    beforeSend(event) {
      if (event.exception) {
        const frames = event.exception.values?.[0]?.stacktrace?.frames;
        if (frames) {
          const errorFileName = frames[frames.length - 1]?.filename;
          console.log("Error file:", errorFileName);
          if (errorFileName && errorFileName.includes("app-page.runtime")) {
            console.log("Dropping error from app-page.runtime");
            return null;
          }
        }
      }
      return event;
    },
    integrations: [new Integrations.BrowserTracing()],
  });
}
